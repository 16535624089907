import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/AuthSlice/authSlice";
import userReducer from "./slices/userListSlice/userListSlice";

const store = configureStore({
  reducer: {
    authData: authReducer,
    userData: userReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
