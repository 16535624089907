import React, { useEffect } from "react";
import swal from "sweetalert";

function FallBackError() {
  useEffect(() => {
    swal({
      title: "Something bad happened!",
      text: "Clear cache and reload by pressing CTRL+SHIFT+R",
      icon: "error",
      buttons: ["Cancel", "Try again"],
      dangerMode: true,
    }).then((clicked) => {
      if (clicked) {
        window.location.assign("/");
      }
    });
  }, []);

  return <div />;
}

export default FallBackError;
