import React, { lazy, Suspense } from "react";

import FullScreenLoader from "../shared/loader/FullScreenLoader";

const DemoApplication = lazy(() => import("./DemoApplication"));

function StartUpScreen() {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <DemoApplication />
    </Suspense>
  );

  // if (authData?.userInfo?.authorities?.includes("ROLE_SUPER_ADMIN")) {
  //   return <DemoApplication />;
  // }

  return null;
}
export default StartUpScreen;
