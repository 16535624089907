import { createContext, useContext } from "react";

import useAxios from "../../common/useAxios";

const NotificationListContext = createContext();

export const useNotificationListContext = () =>
  useContext(NotificationListContext);

export const TerritoryListContextProvider = ({ children }) => {
  const { fetcher, loading, error, data } = useAxios([]);
  const getNotificationList = () => {
    fetcher({
      options: {
        method: "GET",
        url: "/notification/notificationList",
      },
    });
  };

  const value = {
    getNotificationList,
    loading,
    error,
    data,
  };
  return (
    <NotificationListContext.Provider value={value}>
      {children}
    </NotificationListContext.Provider>
  );
};

export default NotificationListContext;
