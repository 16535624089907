import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { baseurl } from "../../common/baseURL";
import {
  convertErrorStatusToText,
  errorMsg,
} from "../../constrainsts/errorMsg";

const LoginContext = createContext();
export const useLogin = () => useContext(LoginContext);

const LoginProvider = ({ children }) => {
  const [tokenData, setTokenData] = useState("");
  const [userInfoData, setUserInfoData] = useState("");
  const [error, setError] = useState("");
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    // console.log("Token===>>", JSON.parse(token));
    if (token) {
      setTokenData(JSON.parse(token));
    }
  }, [token]);
  const showNotification = (data) => {
    window?.Android?.showNotification("true", data?.userId, data?.companyId);
    // console.log({verifyed:"true", userId: username, companyId: data?.companyId});
  };
  const getLogin = async ({ username, password }) => {
    try {
      setLoader(true);
      const { data } = await axios({
        url: `${baseurl}/getLoginResponse`,
        method: "GET",
        auth: {
          username: username,
          password: password,
        },
      });
      if (data) {
        try {
          const { data } = await axios({
            url: `${baseurl}/api/v1/user/getUserInfoBy/${username}`,
            method: "GET",
            auth: {
              username: username,
              password: password,
            },
          });
          if (data) {
            localStorage.setItem("username", username);
            localStorage.setItem("password", password);
            localStorage.setItem("companyId", data.companyId);
            localStorage.setItem("roleName", data?.roleNames[0]);
            showNotification(data);
            // window.location.reload();
            // console.log("userid===>>>",data?.userId);
          }
        } catch (error) {
          errorMsg(error) ?? convertErrorStatusToText(error);
        }
        // const decodedUserToken = jwtDecode(data?.access_token);
        // localStorage.setItem("token", JSON.stringify(data?.access_token));

        // localStorage.setItem("userInfo", JSON.stringify(decodedUserToken));

        // setTokenData(JSON.stringify(data?.access_token));
        // setUserInfoData(decodedUserToken);
        // console.log(data);
      }
      setLoader(false);
    } catch (error) {
      setLoader(true);
      setError(errorMsg(error) ?? convertErrorStatusToText(error));
      setLoader(false);
      // console.log(error?.response);
    }
  };

  const value = {
    getLogin,
    tokenData,
    userInfoData,
    error,
    setError,
    setTokenData,
    loader,
  };

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};

export default LoginProvider;
