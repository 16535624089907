/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { baseurl } from "../../../common/baseURL";

// Slice Creates
const authSlice = createSlice({
  name: "auth",
  initialState: {}, // initial state
  reducers: {
    startLoadingData: (state) => {
      state.loading = true;
      state.error = "";
    },
    getTokenDataSuccessFully: (state, action) => {
      state.token = action?.payload;
      state.loading = false;
      state.error = "";
    },
    getUserInfoDataSuccessFully: (state, action) => {
      state.userInfo = action?.payload;
      state.loading = false;
      state.error = "";
    },
    failedLoadingData: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
    },
    removeLoginData: (state) => {
      state.token = {};
      state.userInfo = {};
      state.loading = false;
      state.error = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoadingData,
  getTokenDataSuccessFully,
  getUserInfoDataSuccessFully,
  failedLoadingData,
  removeLoginData,
} = authSlice.actions;

// Api call for login
export const getAuthData =
  (apiData = {}) =>
    async (dispatch) => {
      const { username, password } = apiData;
      dispatch(startLoadingData());
      if (username && password) {
        try {
          const { data } = await axios.post(
            `${baseurl}/oauth/token?grant_type=password&username=${username}&password=${password}`,
            {},
            {
              headers: {
                Authorization: "Basic d2ViOjEyMw==",
              },
            }
          );
          if (data) {
            dispatch(getTokenDataSuccessFully(data));
            localStorage.setItem("token", JSON.stringify(data));
            const decodeToken = jwtDecode(data?.access_token);
            dispatch(getUserInfoDataSuccessFully(decodeToken));
            localStorage.setItem("userInfo", JSON.stringify(decodeToken));
            // window.location.reload();
          }
        } catch (error) {
          dispatch(
            failedLoadingData(
              error?.response && error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message
            )
          );
        }
      }
    };

export const getReloadAuthData = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  dispatch(getTokenDataSuccessFully(token));
  dispatch(getUserInfoDataSuccessFully(userInfo));
};
const showNotification = (username, companyId) => {
  window?.Android?.showNotification("false", username, companyId);
  // console.log({verifyed:"false", userId: username});
};
export const logOut = (history) => async (dispatch) => {
  const username = localStorage.getItem("username");
  const companyId = localStorage.getItem("companyId");
  history.push("/login");
  window.location.reload();
  localStorage.removeItem("username");
  localStorage.removeItem("password");
  localStorage.removeItem("roleName");
  localStorage.clear();
  showNotification(username,companyId);
  // window.location.reload();
};

export default authSlice.reducer;
