import CombinedProvider from "./Providers/CombinedProvider";
import LoginProvider from "./Providers/LoginProvider";
import NotificationProvider from "./Providers/NotificationContext";
import { TerritoryListContextProvider } from "./Providers/NotificationListContext";
import ThemeProvider from "./Providers/ThemeContext";

const AppProvider = ({ children = null }) => (
  <CombinedProvider
    components={[
      NotificationProvider,
      ThemeProvider,
      LoginProvider,
      TerritoryListContextProvider,
    ]}
  >
    {children}
  </CombinedProvider>
);

export default AppProvider;
