import { createContext, useContext, useState } from "react";
import { socketProtocol, socketURL } from "../../common/baseURL";

export const NotificationContext = createContext();
const { Provider } = NotificationContext;
// const socketURL = ''
export const useNotification = () => useContext(NotificationContext);
function NotificationProvider({ children = null }) {
  const [socketData, SetSocketData] = useState([]);

  const getNotificationData = () => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");

    // const socket = new WebSocket(
    //   "ws://" +
    //     username +
    //     ":" +
    //     password +
    //     "@stms.suffixit.com:8878/eventEmitter"
    // );
    const socket = new WebSocket(
      socketProtocol + username + ":" + password + socketURL
    );
    // Event handler for when the socket connection is open
    const handleOpen = () => {
      console.log("Socket connection opened");
      // Perform any necessary actions when the socket is connected
    };

    // Event handler for receiving messages from the socket
    const handleMessage = (event) => {
      const message = JSON.parse(event.data);

      SetSocketData(message);
      // console.log(message);
      //   SetData(message);
      // Process the received message as needed
    };

    // Event handler for when the socket connection is closed
    const handleClose = () => {
      console.log("Socket connection closed");
      // Perform any necessary cleanup or reconnection logic
    };

    // Attach event listeners to the socket
    socket.addEventListener("open", handleOpen);
    socket.addEventListener("message", handleMessage);
    socket.addEventListener("close", handleClose);

    // Cleanup function to close the socket connection when the component unmounts
    return () => {
      socket.close();
    };
  };

  const data = {
    getNotificationData,
    socketData,
    SetSocketData,
  };

  return <Provider value={data}>{children}</Provider>;
}

export default NotificationProvider;
