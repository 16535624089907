import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";

import { baseurl } from "../../../common/baseURL";
import {
  convertErrorStatusToText,
  errorMsg,
} from "../../../constrainsts/errorMsg";

// Slice Creates
const userListSlice = createSlice({
  name: "userList",
  initialState: {}, // initial state
  reducers: {
    startUserListLoading: (state) => {
      state.loading = true;
      state.error = "";
    },

    getUserListSuccessfully: (state, action) => {
      state.loading = false;
      state.getUserListData = action.payload;
      state.error = "";
    },
    failedUserListData: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startUserListLoading,
  getUserListSuccessfully,
  failedUserListData,
} = userListSlice.actions;

export const getServerUserListAction =
  (pageNumber = 0, pageSize = 5) =>
  async (dispatch) => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const userListUrl = `${baseurl}/api/v1/user/userList?page=${pageNumber}&size=10`;

    dispatch(startUserListLoading());
    try {
      const { data } = await axios.get(userListUrl, {
        headers: { "Content-Type": "application/json" },
        auth: {
          username: username,
          password: password,
        },
      });
      if (data) {
        dispatch(getUserListSuccessfully(data));
      }
    } catch (error) {
      dispatch(
        failedUserListData(errorMsg(error) ?? convertErrorStatusToText(error))
      );
    }
  };

export default userListSlice.reducer;
