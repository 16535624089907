// /+++++++Porduction API+++++++++++++

export const baseurl = "https://stmsapi.suffixit.com";
export const socketProtocol = "wss://";
export const socketURL = "@stmsapi.suffixit.com/eventEmitter";

// /+++++++Test API+++++++++++++

// export const baseurl = "http://172.168.20.218:8878";
// export const socketProtocol = "ws://";
// export const socketURL = "@172.168.20.218:8878/eventEmitter";

///+++++++Nasim_Bro API+++++++++++++

// export const baseurl = "http://172.168.23.85:8878";
// export const socketProtocol = "ws://";
// export const socketURL = "@172.168.23.85:8878/eventEmitter";

// export const baseurl = "https://stms.suffixit.com:8878";
// export const socketProtocol = "wss://"
// export const socketURL = "@stms.suffixit.com:8878/eventEmitter";
