import PropTypes from "prop-types";
import React, { useEffect } from "react";
import swal from "sweetalert";

function OfflineAlert({ isOnline }) {
  useEffect(() => {
    if (!isOnline) {
      swal({
        title: "No Internet Connection Available!",
        text: "Try to connect over mobile network or wifi.",
        icon: "error",
        buttons: ["Cancel", "Try again"],
        dangerMode: true,
      }).then((clicked) => {
        if (clicked) {
          window.location.assign("/");
        }
      });
    }
  }, [isOnline]);

  return <div />;
}

OfflineAlert.propTypes = {
  isOnline: PropTypes.bool.isRequired,
};

export default OfflineAlert;
