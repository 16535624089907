import axios from "axios";
import { useState } from "react";

import { convertErrorStatusToText, errorMsg } from "../constrainsts/errorMsg";
import { baseurl } from "./baseURL";

const useAxios = (initialValue = {}) => {
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  // const username = "user";
  // const password = "password";
  const [response, setResponse] = useState({
    loading: false,
    error: null,
    data: initialValue,
  });
  const [responsedata, setresponseData] = useState("");
  const { loading, error, data } = response;
  const handleReset = () =>
    setResponse({
      loading: false,
      error: "",
      data: initialValue,
    });

  const fetcher = async ({ options, callback }) => {
    setResponse((prev) => ({
      ...prev,
      loading: true,
      error: null,
      data: initialValue,
    }));

    // setAuthToken();

    axios({
      ...options,
      url: baseurl + options?.url,
      auth: {
        username: username,
        password: password,
      },
    })
      .then(({ data, error }) => {
        if (data) {
          setResponse((prev) => ({
            ...prev,
            loading: false,
            error: null,
            data,
          }));

          // if any success message or function dispatch required, we can do this by passing this callback function
          if (typeof callback === "function") {
            return callback({ data });
          }
        }
      })

      .catch((error) => {
        // console.log(error?.response?.data?.error);
        setResponse((prev) => ({
          ...prev,
          loading: false,
          error: errorMsg(error) ?? convertErrorStatusToText(error),
          data: initialValue,
        }));
      });
  };

  return { fetcher, loading, error, data, handleReset, responsedata };
};

export default useAxios;
