import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";
import "tailwindcss/tailwind.css";
import FallBackError from "./components/FallBackError";
import Offline from "./components/Offline";
import StartUpScreen from "./containers/StartUpScreen";
import AppProvider from "./context";
import './App.css';
function App() {
  const handleBoundaryError = (error, errorInfo) => {
    // console.log("Error Occurred ", error, errorInfo);
  };
  return (
    <AppProvider>
      <Router>
        <Offline />
        <ErrorBoundary
          FallbackComponent={FallBackError}
          onError={handleBoundaryError}
        >
          <StartUpScreen />
        </ErrorBoundary>
      </Router>
    </AppProvider>
  );
}

export default App;
